import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import SEO from "../components/SEO";

import "./styles.css";

const IndexPage = ({ data }) => (
  <div className="container">
    <SEO
      title="Octal Group"
      keywords={[
        "corporate intelligence",
        "octal",
        "group",
        "research",
        "development",
        "r&d",
        "software",
        "technology",
      ]}
    />
    <div className="content">
      <Img
        className="logo"
        alt="Octal Group"
        fluid={data.logo.childImageSharp.fluid}
      />
      <div className="info">
        <h2 className="purpose">
          technology intelligence • research & development • product engineering
        </h2>
        <a className="contact" href="mailto:info@octal.group">
          email: info@octal.group
        </a>
        <div>© {new Date().getFullYear()}</div>
      </div>
    </div>
  </div>
);

export const logoQuery = graphql`
  query LogoQuery {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 99) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default IndexPage;
